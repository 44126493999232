import React, { useEffect } from "react";

let scriptLoadingPromise: Promise<void>;

interface Spreadm8CalcWidgetProps {
  spread?: string;
  mode?: string;
  name?: string;
  width?: string;
  height?: string;
  light_mode_background?: string;
  dark_mode_background?: string;
  light_mode_text_color?: string;
  dark_mode_text_color?: string;
  light_mode_input_background?: string;
  dark_mode_input_background?: string;
  light_mode_button_color?: string;
  dark_mode_button_color?: string;
  border_radius?: string;
  input_border_radius?: string;
  shadow?: string;
  opacity?: string;
  show_email_input?: string;
  show_interbank_rate?: string;
  light_mode_border_color?: string;
  dark_mode_border_color?: string;
  light_mode_button_text_color?: string;
  dark_mode_button_text_color?: string;
  show_timezone?: string;
  timezone?: string;
}

const Spreadm8CalcWidget: React.FC<Spreadm8CalcWidgetProps> = ({
  spread = "0.1",
  mode = "light",
  name = "Our Results",
  width = "100%",
  height = "100%",
  light_mode_background = "#ffffff",
  dark_mode_background = "#1f2937",
  light_mode_text_color = "#1f2937",
  dark_mode_text_color = "#f9fafb",
  light_mode_input_background = "#f9fafb",
  dark_mode_input_background = "#374151",
  light_mode_button_color = "#5AC3D7",
  dark_mode_button_color = "#374151",
  border_radius = "50px",
  input_border_radius = "14",
  shadow = "lg",
  opacity = "100",
  show_email_input = "false",
  show_interbank_rate = "false",
  light_mode_border_color = "#5AC3D7",
  dark_mode_border_color = "#4b5563",
  light_mode_button_text_color = "#FFFFFF",
  dark_mode_button_text_color = "#f9fafb",
  show_timezone = "true",
  timezone = "Africa/Johannesburg",
}) => {
  useEffect(() => {
    if (!scriptLoadingPromise) {
      scriptLoadingPromise = loadScript();
    }

    function loadScript() {
      return new Promise<void>((resolve) => {
        const script = document.createElement("script");
        script.src =
          "https://cdn.jsdelivr.net/npm/@webcomponents/webcomponentsjs@2.8.0/webcomponents-loader.min.js";
        script.onload = () => {
          const scriptModule = document.createElement("script");
          scriptModule.type = "module";
          scriptModule.src =
            "https://cdn.jsdelivr.net/gh/integritas-management/integritas-costcalculator-widget@v1.0.7/dist/lib/spreadm8-widget.min.js";
          document.head.appendChild(scriptModule);
          resolve();
        };

        document.head.appendChild(script);
      });
    }
  }, []);

  return (
    // Adjust the width and height of the widget here
    <div style={{ height: "550px", width: "60%" }}>
      <spreadm8-calc
        spread={spread}
        mode={mode}
        name={name}
        width={width}
        height={height}
        light_mode_background={light_mode_background}
        dark_mode_background={dark_mode_background}
        light_mode_text_color={light_mode_text_color}
        dark_mode_text_color={dark_mode_text_color}
        light_mode_input_background={light_mode_input_background}
        dark_mode_input_background={dark_mode_input_background}
        light_mode_button_color={light_mode_button_color}
        dark_mode_button_color={dark_mode_button_color}
        border_radius={border_radius}
        input_border_radius={input_border_radius}
        shadow={shadow}
        opacity={opacity}
        show_email_input={show_email_input}
        show_interbank_rate={show_interbank_rate}
        light_mode_border_color={light_mode_border_color}
        dark_mode_border_color={dark_mode_border_color}
        light_mode_button_text_color={light_mode_button_text_color}
        dark_mode_button_text_color={dark_mode_button_text_color}
        show_timezone={show_timezone}
        timezone={timezone}
      ></spreadm8-calc>
    </div>
  );
};

export default Spreadm8CalcWidget;
