import { Global, css } from "@emotion/react";
import { colors } from "../../constants/colors";

const GlobalStyles = () => (
  <Global
    styles={css`
      html,
      body,
      #root {
        width: 100vw;
        min-height: 100vh;
      }

      html {
        /** 1rem = 10px; 10px/16px = 62.5% **/
        /* font-size: 62.5%; */
        box-sizing: border-box;
      }

      body {
        /** Typography **/
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        line-height: 14px;
        background-color: ${colors.blue1};
        color: ${colors.black1};
        font-size: 15px;
        width: 100%;
        min-height: 100vh;
      }

      *,
      *::after,
      *::before {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
      }

      input,
      textarea {
        /* Removing styling for inputs for iOS */
        appearance: none;
      }

      input:focus,
      select:focus,
      textarea:focus,
      button:focus {
        outline: none;
      }
    `}
  />
);

export default GlobalStyles;
