import * as React from "react";
import { IoCloseOutline } from "react-icons/io5";
// @mui
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// @ld
import { colors } from "../../constants/colors";
// @local
import { Heading4 } from "../../styles/typography";
import { Dialog, DialogHeader } from "./styles";

type ModalProps = {
  id: string;
  open: boolean;
  showDivider?: boolean;
  children: React.ReactNode;
  hideCancelButton?: boolean;
  title: string | React.ReactNode;
  onClose: (props?: object) => void;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
};

export type TActionModalProps = {
  open: boolean;
  onClose: () => void;
  onError?: (error: object) => void;
  onSuccess?: (data?: object) => void;
};

const Modal = ({
  id,
  open,
  title,
  onClose,
  children,
  showDivider,
  maxWidth = "xs",
  hideCancelButton = false,
}: ModalProps) => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      fullWidth
      open={open}
      fullScreen={isXS}
      maxWidth={maxWidth}
      onClose={(_: any, reason: string) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      aria-labelledby={id}
    >
      <DialogHeader id={id}>
        {typeof title === "string" ? <Heading4>{title}</Heading4> : title}
        {!hideCancelButton && (
          <IconButton
            size="small"
            sx={{ background: colors.grey4 }}
            onClick={onClose}
          >
            <IoCloseOutline size={16} />
          </IconButton>
        )}
      </DialogHeader>
      {showDivider && <Divider />}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
