import Modal from "../modal";

import help from "../../assets/help-img.png";
import { THelpProps } from "./types";

const HelpModal = ({ open, onClose }: THelpProps) => {
  return (
    <Modal open={open} id="help" title="Help" onClose={onClose}>
      <img src={help} alt="Example" style={{ width: "100%" }} />
    </Modal>
  );
};

export default HelpModal;
