import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import * as React from "react";

import theme from "./theme";

const AppThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <MUIThemeProvider theme={theme}>
    <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
  </MUIThemeProvider>
);

export default AppThemeProvider;
