import { createTheme } from "@mui/material/styles";

import { colors } from "../../constants/colors";

export default createTheme({
  typography: {
    fontFamily: '"Roboto", sans-serif',
    button: {
      fontSize: "inherit",
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  palette: {
    primary: {
      light: colors.blue2,
      main: colors.blue1,
      dark: colors.blue1,
    },
    secondary: {
      light: colors.blue1,
      main: colors.white,
      dark: colors.white,
    },
    error: {
      light: colors.blue2,
      main: colors.blue1,
      dark: colors.blue1,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#ffffff",
          boxShadow: "none",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 600,
          borderRadius: 10,
          lineHeight: "20px",
          padding: "13px 30px",
          textTransform: "inherit",
        },
        sizeLarge: {
          padding: "15px 30px",
        },
        contained: {
          boxShadow: "none",
          "&:disabled": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          color: "#ffffff",
          boxShadow: "none",
          background: colors.blue1,
          fontSize: 16,
          "&:disabled": {
            color: "#ffffff",
            background: colors.blue1,
            opacity: 0.5,
          },
          "&:hover": {
            color: "#ffffff",
            boxShadow: "none",
          },
          "&:active": {
            color: "#ffffff",
          },
        },
        outlined: {
          border: `1px solid ${colors.grey4}`,
          color: colors.grey1,
        },
        outlinedPrimary: {
          "&:disabled": {
            opacity: 0.5,
            color: colors.blue1,
            border: `1px solid ${colors.blue1}`,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&:disabled": {
            background: colors.grey4,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
        input: {
          padding: 15,
        },
      },
    },
  },
});
