import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colors } from "../../constants/colors";

type BaseStylesProps = {
  align?: string;
  color?: string;
  weight?: string | number;
  noWrap?: boolean;
  textTransform?: string;
};

const ellipsisStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const getBaseStyle = (props: BaseStylesProps) => css`
  backface-visibility: hidden;
  font-family: "Montserrat", sans-serif;
  text-align: ${props.align || "left"};
  color: ${props.color || colors.black1};
  font-weight: ${props.weight || "normal"};
  text-transform: ${props.textTransform || "normal"};
  ${props.noWrap && ellipsisStyle}
`;

export const Heading1 = styled.h1<BaseStylesProps>`
  ${(props) => getBaseStyle(props)}
  font-size: 42px;
  font-weight: bold;
  line-height: 51px;
`;

export const Heading2 = styled.h2<BaseStylesProps>`
  ${(props) => getBaseStyle(props)}
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
`;

export const Heading3 = styled.h3<BaseStylesProps>`
  ${(props) => getBaseStyle(props)}
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
`;

export const Heading4 = styled.h4<BaseStylesProps>`
  ${(props) => getBaseStyle(props)}
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const Heading5 = styled.h5<BaseStylesProps>`
  ${(props) => getBaseStyle(props)}
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export const BodyLargeText = styled.p<BaseStylesProps>`
  ${(props) => getBaseStyle(props)}
  font-size: 20px;
  line-height: 30px;
`;

export const BodySmallText = styled.p<BaseStylesProps>`
  ${(props) => getBaseStyle(props)}
  font-size: 16px;
  line-height: 24px;
`;

export const SubText = styled.p<BaseStylesProps>`
  ${(props) => getBaseStyle(props)}
  font-size: 12px;
  line-height: 16px;
`;
