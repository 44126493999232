import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ThemeProvider from "./context/ThemeProvider";
import GlobalStyles from "./styles/global";

import { SpeedInsights } from "@vercel/speed-insights/react";

import { urls } from "./constants/urls";

import Data from "./pages/data";
import Home from "./pages/home";

import PageLoader from "./components/pageloader";

const App = () => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <SpeedInsights />
      <Router>
        <React.Suspense fallback={<PageLoader />}>
          <Routes>
            <Route path={`${urls.root}`} element={<Home />} />
            <Route path={`${urls.dataUrl}`} element={<Data />} />
          </Routes>
        </React.Suspense>
      </Router>
    </ThemeProvider>
  );
};

export default App;
