import { Box, Button, Stack } from "@mui/material";
import HelpModal from "../../components/help";
import Hero from "../../components/hero";
import Spreadm8CalcWidget from "../../components/spreadm8";

import { useState } from "react";
import { IoHelpCircleSharp } from "react-icons/io5";
import { colors } from "../../constants/colors";
import { Heading4 } from "../../styles/typography";

const Home = () => {
  const [viewHelp, setViewHelp] = useState(false);
  return (
    <Stack direction="row" sx={{ py: 20, height: "100%" }}>
      <Stack
        sx={{ width: "50%", height: "100%" }}
        justifyContent="center"
        alignItems="center"
      >
        <Hero />
      </Stack>
      <Stack
        sx={{ width: "50%", height: "100%" }}
        justifyContent="center"
        alignItems="center"
      >
        <Spreadm8CalcWidget />
        <Box sx={{ height: "16px" }} />
        <Button
          color="secondary"
          endIcon={<IoHelpCircleSharp size={35} color={colors.blue1} />}
          onClick={() => {
            setViewHelp(true);
          }}
        >
          <Heading4 color={colors.blue1}>Help</Heading4>
        </Button>
        <HelpModal
          open={viewHelp}
          onClose={() => {
            setViewHelp(false);
          }}
        />
      </Stack>
    </Stack>
  );
};

export default Home;
