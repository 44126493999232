import { Stack, Typography } from "@mui/material";
import ResultsIcon from "../../assets/ResultsIcon.svg";
import { colors } from "../../constants/colors";

const Hero = () => {
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ height: "550px" }}>
      <img style={{ width: "40%" }} src={ResultsIcon} alt="Mobile Chat Icon" />
      <Stack alignItems="left">
        <Typography color={colors.white} variant="h5">
          Discover the Rates Your Currency Provider is
        </Typography>
        <Typography color={colors.white} variant="h3">
          Sneaking Past You!
        </Typography>
        <Typography color={colors.white} variant="body1">
          Using this simple tool, you can reveal how much profit your provider
          made from your exchange
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Hero;
