export const colors = {
  black1: "#313842",
  black2: "#EFEFF0",
  white: "#FFFFFF",
  brightGreen1: "#03C898",
  brightGreen2: "#E6F9F5",
  green1: "#4BABB3",
  green2: "#EDF7F7",
  green3: "#F1F8F9",
  red1: "#FF8863",
  red2: "#FFF3EF",
  blue1: "#46b4e6",
  blue2: "#5ac3d7",
  yellow1: "#FFC150",
  yellow2: "#FFF9ED",
  grey1: "#868686",
  grey2: "#D7D7D7",
  grey3: "#E5E5E5",
  grey4: "#EFEFEF",
  grey5: "#F6F6F6",
};
